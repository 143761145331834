<template>
  <div :id="id" />
</template>

<script setup lang="ts">
import { computed } from 'vue';

type SectionAnchorProps = {
    id: string;
}

const props = defineProps<SectionAnchorProps>();

// remove # from the start of the id if it exists
const id = computed(() => props.id.replace(/^#/, ''));
</script>